import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home.vue"),
    props:true
  },
  {
    path: "/tournaments-information",
    name: "tournaments-information",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule.vue"),
      props: true
  },
  {
    path: "/reports-page", 
    name: "reports-page",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/reports.vue"),
    props: true
  },
  {
    path: "/players",
    name: "players",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/players.vue"),
      props:true
  },
  {
    path: "/active-players",
    name: "active-players",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/activePlayers.vue"),
      props:true
  },
  {
    path: "/oom",
    name: "oom",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
      props: true
  },
  {
    path: "/costa-del-sol-order-of-merit",
    name: "costa-del-sol-order-of-merit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
      props: true
  },
  {
    path: "/letas-order-of-merits",
    name: "letas-order-of-merits",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
      props: true
  },
  {
    path: "/letas-roty",
    name: "letas-roty",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
      props: true
  },
  {
    path: "/let-cup-solheim",
    name: "let-cup-solheim",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
      props: true
  },
  {
    path: "/rolex-world-rankings-2",
    name: "rolex-world-rankings-2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
      props: true
  },
  {
    path: "/player-profiles/:refno/",
    name: "player-profiles",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/playerprofile.vue"),
    props: true
  },
  {
    path: "/news",
    name: "News",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/news.vue")
  },
  {
    path: "/videos",
    name: "videos",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/video.vue"),
      props:true
  },
  {
    path: "/stats",
    name: "stats",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/stats.vue"),
      props:true
  },
  {
    path: "/fullStats",
    name: "fullStats",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/fullStats.vue"),
      props:true
  },
  {
    path: "/tournaments-information/:course_code/:id",
    name: "Tournament",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tournament.vue"),
    props: true
  },
  // {
  //   path: "/tourschool",
  //   name: "tourschool",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/tourschool.vue"),
  //   props: true
  // },
  {
    path: "/partners-suppliers",
    name: "partners-suppliers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/partners.vue"),
    props: true
  },
  {
    path: "/develop",
    name: "develop",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/develop.vue"),
    props: true
  },
  {
    path: "/teameurope",
    name: "team-europe",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/teamEurope.vue"),
    props: true
  },
  {
    path: "/oceantee/:id",
    name: "oceantee",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/ra",
    name: "ra",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/lacoste",
    name: "lacoste",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/celebrating-the-green/",
    name: "celebrating-the-green",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/geofoundation/:id",
    name: "geofoundation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/johndeer/:id",
    name: "johndeer",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/leadingcourses/:id",
    name: "leadingcourses",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/randa/:id",
    name: "randa",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/lacoste/:id",
    name: "lacoste",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/rookie/:id",
    name: "rookie",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/board-of-directors",
    name: "board-of-directors",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/membership",
    name: "membership",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/adidas",
    name: "adidas",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/about-letas-tour",
    name: "about-letas-tour",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/contact-let",
    name: "contact-let",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/careers",
    name: "careers",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/competition-tcs",
    name: "competition-tcs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/newsletter/:id",
    name: "newsletter",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/the-let-golf-podcast",
    name: "the-let-golf-podcast",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
    {
    path: "/the-letas-golf-podcast",
    name: "the-letas-golf-podcast",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
    props: true
  },
    {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/search.vue"),
    props: true
  },
  {
    path: "/tourschool",
    name: "tourschool",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/q-school.vue"),
    props: true
  },
  {
    path: "/blog/:id",
    name: "newStory",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  {
    path: "/:id",
    name: "newStory",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  { 
    path: '/:catchAll(.*)', 
    name: 'NotFound'
  }
];

const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});


export default router;
